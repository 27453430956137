import React, {Component} from "react"
import { Link } from 'gatsby'
import {
	Button,
	Form,
	Message,
	Header
} from 'semantic-ui-react'
import * as styles from './styles/forms.module.css'
import {
	confirmationColor,
	backColor
} from '../../globalVar'

const Negosyo = require('../../../lib/negosyo')



export default class SignInForm extends Component {
	state = {
		contact: '',
		password: '',
		error: '',
		loading:false,
		loginType:"Password",
		timeOut1:undefined,
		errorMessage: {
			contact: undefined,
			password: undefined
		}
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1)
	}

	validateInput = (states) => {
		// Copied from CartSummary
		for( let key2 of Object.keys(states) ) {
			if(states[key2] !== undefined && key2 !== "password") {
				this.setState({
					[key2]:this.state[key2].trim()
				})
				states[key2] = states[key2].trim()
			}
		}

		let check = true;

		//needs to check for undefined since for some reason the forms start as undefined value
		for( let key of Object.keys(states) ) {
			if(states[key]==='' || states[key]===undefined ) {
				this.setState( prevState=>({
					errorMessage: {
						...prevState.errorMessage,
						[key]:"this must not be blank"
					}

				}))
				check = false
			}else if(key==="contact") {
				const pattern = /^(0|\+63)(8|9)([0-9]{9})$/g
				const result = pattern.test(states[key])
				if(!result) {
					check = false
					this.setState(prevState => ({
						errorMessage: {
							...prevState.errorMessage,
							contact : "must be a valid mobile number"
						}
					}))
				}

			}
		}
		return check;
	}

	handleInputChange = (e, {name, value}) => {
		if (name === "contact" || name === "email") {
			value = value.replace(/\s/g,"");
		}

		this.setState({ [name]: value })
		if( value==='' ) {
			const message = name + " must not be blank"
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		} else {
			const message = undefined
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		}
	}

	login = () => {
		const {
			contact,
			password
		} = this.state
		if( this.validateInput({contact, password}) ) {

			this.setState({loading:true})
			Negosyo.getUser(contact)
			.then((data)=>{
				if(data.status==="Active") {
					if(data.logintype==="Password"){
						Negosyo.login(data.username, password)
						.then(()=>{
							//do get current authenticated user? and out all the stuff after this inside its then to make sure we really are logged in?
							this.setState({
								loading:false,
							})
							this.props.handleSubmit()

						})
						.catch((e)=>{
							/*
							this.setState({
								error:e.message,
								loading:false
							})
							*/
							this.setState({
								loading:false,
								error:(
									<React.Fragment>
										Having trouble signing in?<br/>
										<Link to="/reset-password/"> Click here to reset password </Link>

									</React.Fragment>
								)
							})
						})
					}else {
						//user is OTP not official account so act like non existent account
						this.setState({
							loading:false,
							error:"There is no account with that contact detail"
						})
					}
				}else if(data.status==="Inactive"){

					this.setState({
						error:"There is no account with that contact detail",
						loading:false
					})
					/*
					const temp = setTimeout( ()=>{
						navigate("/register/")
					}, 3000)
					this.setState({timeOut1:temp})
					*/
				}else if(data.status==="None") {
					this.setState({
						loading:false,
						error:"There is no account with that contact detail"
					})
				}else if(data.status==="Error") {
					this.setState({
						loading:false,
						error:"Error occurred, please try again"
					})
					//this.setState({error:"Unable to login. Please try again"})
				}else {
					this.setState({
						loading:false,
						error:"Error occurred, please try again"
					})
				}

			}).catch((err)=>{
				this.setState({
					loading:false,
					error:"Error occurred, please try again"
				})
			})
		}
	}

	render() {
		const {
			contact,
			password,
			loading,
			errorMessage,
		} = this.state
		const {login, handleInputChange} = this
		const {
			shortenForm
		} = this.props
		return (
			<React.Fragment>
				<Header className={styles.header}>
					Sign In
				</Header>


				<Form onSubmit={login} >
					<Form.Input
						label ="Mobile #"
						placeholder='Mobile #'
						error={errorMessage.contact}
						value={contact}
						name='contact'
						onChange={handleInputChange}
						className={shortenForm ? `${styles.form} ${styles.formShortened}`:styles.form}
						id={"signInContact"}
					/>
					<Form.Input
						label="Password"
						placeholder="Password"
						error={errorMessage.password}
						type="password"
						value={password}
						name='password'
						onChange={handleInputChange}
						className={shortenForm ? `${styles.form} ${styles.formShortened}`:styles.form}
						id={"signInPassword"}
					/>
					{this.state.error &&
						<Message negative className={styles.msg} id={"signInMessage"} name={"signInErrorMsg"}>
							{this.state.error}
						</Message>
					}
					{this.props.backFunc && (
						<Button
							color={backColor}
							content={this.props.backMessage ? this.props.backMessage:"Back"}
							onClick={this.props.backFunc}
							className={this.props.backStyle ? this.props.backStyle:styles.form}
							disabled={this.props.backDisabled}
							type="button"
							id={"signInBackButton"}
							name={"signInBackButton"}
						/>
					)}
					<Button
						color={confirmationColor}
						loading={loading}
						disabled={loading}
						className={this.props.backFunc ? styles.mainButton:styles.form}
						id={"signInProceedButton"}
						name={"signInProceedButton"}
					>
						Proceed
					</Button>
				</Form>
			</React.Fragment>
		)
	}
}

SignInForm.defaultProps = {
	shortenForm:false
}
