import React from "react"
import { Link, navigate } from 'gatsby'
import {
	Button,
	Form,
	Header,
	Message,
} from 'semantic-ui-react'
import * as styles from '../styles/forms.module.css'
import Prompt from '../../Prompt'
import {
	confirmationColor,
	backColor
} from '../../../globalVar'

const Negosyo = require('../../../../lib/negosyo')


class SignUp extends React.Component {
	state = {
		name: '',
		password: '',
		confirmPassword:'',
		contact: '',
		auth_code:'',
		error: '',
		loading:false,
		showPass:false,//to show the password
		showCPass:false,//to show the confirm password password
		openPrompt:false,
		message:'',
		errorMessage: {
			name:undefined,
			password: undefined,
			confirmPassword: undefined,
			contact: undefined,
		}
	}

	handleInputChange = (e, {name, value}) => {
		if (name === "contact" || name === "email") {
			value = value.replace(/\s/g,"");
		}
		this.setState({ [name]: value })
		if( value==='' ) {
			let message =  "This must not be blank"

			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		} else {
			const message = undefined
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		}
	}

	//for only  when u try to sign up but user is inactive
	resendCode = () => {
		const {
			contact
		} = this.state
		if( contact === undefined || contact === '' ) {
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					contact:"must not be blank"
				}
			}))
		}
		else {
			Negosyo.getUser(contact)
			.then((data)=>{
				if(data.status==="Inactive") {
					Negosyo.resendNegosyoCode(contact, data.username)
					.then((data)=>{
						if(data.status==="OK") {
							//add a way to show that we sent an otp to their number
							//this.setState({countdown:60})
						}else if(data.status==="Error") {
							this.setState({error:data.message})
						}

					})
					.catch(e =>{
						this.setState({error:e.message})
					})
				}
			}).catch((err)=>{

			})
		}

	}

	//toggle show of respective password
	toggleShow = (pass) => {
		this.setState({[pass]:!this.state[pass]})
	}

	//check if password is within min length and matches confirm Password
	validateInput = () => {
		let check = true;
		const {
			name,
			password,
			confirmPassword,
			contact,
		} = this.state
		const test = { name, password, confirmPassword, contact }

		//needs to check for undefined since for some reason the forms start as undefined value
		for( let key of Object.keys(test) ) {
			var tmpval = ''
			// Copied from CartSummary
			if(this.state[key]!==undefined) {
				if (key !== "password" && key !== "confirmPassword") {
					this.setState({
						[key]:this.state[key].trim()
					})
					tmpval = this.state[key].trim()
				} else {
					// Don't trim passwords
					tmpval = this.state[key]
				}
			}

			if(tmpval==='' || this.state[key]===undefined ) {
				this.setState( prevState=>({
					errorMessage: {
						...prevState.errorMessage,
						[key]:"this must not be blank"
					}

				}))
				check = false
			}else if( key==="contact" ) {
				const pattern = /^(0|\+63)(8|9)([0-9]{9})$/g
				const result = pattern.test(tmpval)
				if(!result) {
					check = false
					this.setState(prevState => ({
						errorMessage: {
							...prevState.errorMessage,
							contact : "must be a valid mobile number"
						}
					}))
				}

			}else if( key==="password" ) {
				const re = /^[\S]+.*[\S]+$/
				if (password.length<6) {
					this.setState(prevState => ({
						errorMessage: {
							...prevState.errorMessage,
							password:"password length must be greater than 5"
						}
					}))
					check = false;
				} else if (!re.test(password)) {
					this.setState(prevState => ({
						errorMessage: {
							...prevState.errorMessage,
							password:"password must not start or end with a space"
						}
					}))
					check = false;
				} else if (password!==confirmPassword) {
					this.setState(prevState => ({
						errorMessage: {
							...prevState.errorMessage,
							confirmPassword:"Passwords must match"
						}
					}))
					check = false;
				}
			}
		}


		return check;
	}

	closePrompt = () => {
		this.setState({
			openPrompt:false
		})
	}

	signUp = () => {


		this.setState({name:this.state.name.trim(), error:''}, ()=>{
			let check = true
			check = this.validateInput()
			if(check) {
				this.setState({loading:true})
				const {
					name,
					contact,
					password,
				} = this.state

				this.setState({loading:true})
				Negosyo.getUser(contact)
				.then((data)=>{
					if(data.status==="None" ) {
						Negosyo.registerUser(contact, this.props.loginType, name)
						.then((data)=>{
							if(data.status==="OK") {
								Negosyo.register(data.username, password )
								.then((temp)=>{
									Negosyo.loginOTP(data.username, `${data.otp}_${contact}`)
									.then(()=>{
										this.setState({
											loading:false,
											success:"You Signed Up Successfully"
										})
										this.props.handleSubmit(data.username)
									}).catch(e =>{
										this.setState({error:"Error occurred, please try again", loading:false})
									})

									//this.props.handleSubmit(contact, password)
								}).catch(e =>{
									if(e.message.toLowerCase()==="invalid lambda function output : invalid json"){
										//act like everything is normal
										Negosyo.loginOTP(data.username, `${data.otp}_${contact}`)
										.then(()=>{
											this.setState({
												loading:false,
												success:"You Signed Up Successfully"
											})
											this.props.handleSubmit(data.username)
										}).catch(e =>{
											this.setState({error:"Error occurred, please try again", loading:false})
										})

										//this.props.handleSubmit(contact, password)
									}else {
										this.setState({error:"Error occurred, please try again", loading:false})
									}
								})
							}else {
								this.setState({loading:false, error:"Error occurred, please try again"})
							}
						}).catch((e)=>{
							this.setState({error:"Error occurred, please try again", loading:false})
						})
					}else if(data.status==="Inactive"){
						/* this huge register code is for handling the fall out for not handling api
						 * failure when making an account for a user ( basically this is temporary )
						 */
						Negosyo.register(data.username, password )
						.then((temp)=>{
							Negosyo.getOTPCode(contact, data.username)
							.then((response)=>{
								if(response.status==="OK") {
									Negosyo.loginOTP(data.username, `${response.otp}_${contact}`)
									.then(()=>{
										if(data.logintype==="Password") {
											this.setState({
												loading:false,
												success:"You Signed Up Successfully"
											})
											this.props.handleSubmit(data.username)
										}else if(data.logintype==="OTP") {
											this.handleChangePassword(data.username,contact, password)
										}
									}).catch(e =>{
										this.setState({error:"Error occurred, please try again", loading:false})
									})
								}else {
									this.setState({error:"Error occurred, please try again", loading:false})
								}
							}).catch(e =>{
								this.setState({error:"Error occurred, please try again", loading:false})
							})


						}).catch(e =>{
							if(
								e.message.toLowerCase()==="invalid lambda function output : invalid json" ||
								e.message.toLowerCase()==="user already exists"
							){
								//act like everything is normal
								Negosyo.getOTPCode(contact, data.username)
								.then((response)=>{
									if(response.status==="OK") {
										Negosyo.loginOTP(data.username, `${response.otp}_${contact}`)
										.then(()=>{
											if(data.logintype==="Password") {
												if(e.message.toLowerCase()==="invalid lambda function output : invalid json") {
													this.setState({
														loading:false,
														success:"You Signed Up Successfully"
													})
													this.props.handleSubmit(data.username)
												}else {
													/*user already exists so password wasn't set properly*/
													this.handleChangePassword(data.username,contact, password)
												}

											}else if(data.logintype==="OTP") {
												this.handleChangePassword(data.username,contact, password)
											}
										}).catch(e =>{
											this.setState({error:"Error occurred, please try again", loading:false})
										})
									}else {
										this.setState({error:"Error occurred, please try again", loading:false})
									}
								}).catch(e =>{
									this.setState({error:"Error occurred, please try again", loading:false})
								})
							}else {
								this.setState({error:"Error occurred, please try again", loading:false})
							}
						})
						//alert("Your account is inactive. Activate your account. sent an OTP to your contact")
						//this.resendCode()
						//this.props.handleSubmit(contact, password)

						//possibly loginOTP failed for some reason or the aws register failed for some reason

					}else if(data.status==="Active") {
						if(data.logintype==="Password") {

							this.setState({
								error:"There is already an account with that mobile number",
								loading:false
							})
						}else if(data.logintype==="OTP") {
							this.setState({
								loading:false,
								openPrompt:true
							})
						}
					}else if(data.status==="Error") {
						this.setState({
							error:"Error occurred, please try again", //invalid contact?
							loading:false,
						})
					}else {
						this.setState({
							error:"Error occurred, please try again", //error in api?
							loading:false,
						})
					}
				}).catch((err)=>{
					this.setState({
						error:"Error occurred, please try again",
						loading:false
					})
				})

			}
		})

	}

	handleChangePassword = (username,contact, password) => {
		Negosyo.negosyoChangePassword(username, password)
		.then((result)=>{
			if(result.status==="OK") {
				this.setState({
					loading:false,
					success:"You Signed Up Successfully"
				})
				this.props.handleSubmit(username)
			}else {
				Negosyo.logOut()
				.then(()=>{
					this.setState({
						loading:false,
						message:(
							<React.Fragment>
								There was a problem activating your account.  An authorization code has been sent to your number.  Please click <Link to="/reset-password//" state={{contact:contact}}>here</Link> and reset your password to complete the process
							</React.Fragment>
						)
					})
				}).catch((err)=>{
				})
			}
		}).catch((error)=>{
			Negosyo.logOut()
			.then(()=>{
				this.setState({
					loading:false,
					message:(
						<React.Fragment>
							There was a problem activating your account.  Please click <Link to="/app/verify-contact/" state={{contact:contact}}>here</Link> to verify your number and re-enter your password to complete the process
						</React.Fragment>
					)
				})
			}).catch((err)=>{

			})
		})
	}

	render() {

		const {
			signUp,
			handleInputChange,
			toggleShow,
			closePrompt
		} = this
		const {
			name,
			contact,
			password,
			confirmPassword,
			errorMessage,
			showPass,
			showCPass,
			loading,
			openPrompt
		} = this.state

		let icon
		let icon2
		if(showPass) {
			icon="eye slash"
		} else {
			icon="eye"
		}
		if(showCPass) {
			icon2="eye slash"
		} else {
			icon2="eye"
		}
		const {
			shortenForm
		} = this.props
		return (
			<React.Fragment>
				<Header className={styles.header}>Sign Up</Header>

				<Prompt
					open={openPrompt}
					closePrompt={closePrompt}
					content={{
						content:(
							<React.Fragment>
								Your mobile number has been used for a previous transaction<br/>
								Please confirm that, it is your mobile number<br/>
							</React.Fragment>
						),

					}}

					confirmButtonProps={{
						onClick:()=>{
							navigate(`/app/verify-contact/`, {state:{contact:contact}})
						}
					}}
					modalProps={{
						onClose:closePrompt
					}}
				/>

				<Form onSubmit={signUp}>
					<Form.Input
						label ="Name*"
						placeholder='Name'
						error={errorMessage.name}
						value={name}
						name='name'
						onChange={handleInputChange}
						className={shortenForm ? `${styles.form} ${styles.formShortened}`:styles.form}
					/>
					<Form.Input
						label ="Mobile #*"
						placeholder='Mobile #'
						error={errorMessage.contact}
						value={contact}
						name='contact'
						onChange={handleInputChange}
						className={shortenForm ? `${styles.form} ${styles.formShortened}`:styles.form}
					/>
					<Form.Input
						label="Password*"
						placeholder="minimum of 6 characters"
						type={showPass ? undefined:"password"}
						error={errorMessage.password}
						value={password}
						name='password'
						onChange={handleInputChange}
						action={{
							icon:icon,
							onClick: ()=>toggleShow("showPass"),
							type:"button"
						}}
						className={shortenForm ? `${styles.form} ${styles.formShortened}`:styles.form}
					/>
					<Form.Input
						label="Confirm Password*"
						placeholder="Password"
						type={showCPass ? undefined:"password"}
						error={errorMessage.confirmPassword}
						value={confirmPassword}
						name='confirmPassword'
						onChange={handleInputChange}
						action={{
							icon: icon2,
							onClick: ()=>toggleShow("showCPass"),
							type:"button"
						}}
						className={shortenForm ? `${styles.form} ${styles.formShortened}`:styles.form}
					/>
					{this.state.message &&
						<Message className={styles.msg}>
							<Message.Header>{this.state.message}</Message.Header>
						</Message>
					}
					{this.state.error &&
						<Message negative className={styles.msg}>
							<Message.Header>{this.state.error}</Message.Header>
						</Message>
					}
					{this.props.backFunc && (
						<Button
							color={backColor}
							content={this.props.backMessage ? this.props.backMessage:"Back"}
							onClick={this.props.backFunc}
							className={this.props.backStyle ? this.props.backStyle:styles.form}
							disabled={this.props.backDisabled}
							type="button"
						/>
					)}
					<Button
						color={confirmationColor}
						loading={loading}
						disabled={loading}
						className={this.props.backFunc ? styles.mainButton:styles.form}
					>
						Proceed
					</Button>
				</Form>
			</React.Fragment>
		)
	}
}


export default SignUp

SignUp.defaultProps = {
	shortenForm:false
}


